export const ActionTemplateMixin = {

    props: {
        templateData: {},
        result: {},
    },

    data() {
        return {
            actionData: null,
        }
    },

    methods: {
        doAction(){
            this.$emit('do-action', this.actionData)
        }
    }
}